import { useEffect, useRef, useState } from "react";
import PanoramaViewer from "./PanoramaViewer";
import { OurConfig } from "./PanoramaViewer/types";
import localConfig from "./PanoramaViewer/config.json";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { makePreviewConfig } from "./makePreviewConfig";

const App = () => {
  const initialed = useRef<boolean>(false);

  const [config, setConfig] = useState<OurConfig | undefined>(
    process.env.REACT_APP_LOCAL_ENV === "true"
      ? (localConfig as unknown as OurConfig)
      : undefined
  );

  // Fetch from S3
  useEffect(() => {
    if (config) {
      return;
    }

    const init = async () => {
      if (initialed.current) {
        return;
      }

      initialed.current = true;

      const code = window.location.pathname.replace("/", "");

      if (!code) {
        return;
      }

      const res = await fetch(
        `${process.env.REACT_APP_CONFIGURATION_ENDPOINT}/${code}/config.json`
      );

      if (res.status !== 200) {
        const previewConfig = await makePreviewConfig();
        setConfig(previewConfig);
        return;
      }

      const json = await res.json();

      setConfig(json);
    };

    init();
  }, [config]);

  return (
    <HelmetProvider>
      {config && (
        <Helmet>
          <title>{config?.name} | Virtual Tour</title>
          <meta name="description" content={config?.subtitle} />
          <meta
            property="og:image"
            content={config?.spaces[0].scenes[0].thumbnail}
          />
        </Helmet>
      )}

      <PanoramaViewer config={config} />
    </HelmetProvider>
  );
};

export default App;
