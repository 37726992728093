import { useState } from "react";
import { createUseStyles } from "react-jss";
import { OurConfig, PannellumViewer, Space } from "../../../types";
import { appendSceneIdQuery, getSceneIdFromQuery } from "../../../utils/utils";

type StyleProps = { showModal: boolean };

type ModalProps = {
  viewer: PannellumViewer | null;
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  config: OurConfig | undefined;
  setIsLoaded: React.Dispatch<React.SetStateAction<boolean>>;
};

export const Modal: React.FC<ModalProps> = ({
  viewer,
  showModal,
  setShowModal,
  config,
  setIsLoaded
}) => {
  const sceneIdFromQuery = getSceneIdFromQuery();

  const [spaceSelected, setSpaceSelected] = useState<Space | undefined>(
    !sceneIdFromQuery
      ? config?.spaces[0]
      : config?.spaces.find((space) =>
          space.scenes.find((scene) => scene.id === sceneIdFromQuery)
        )
  );

  const classes = useStyles({ showModal });

  return (
    <div className={classes.wrap}>
      <div className={classes.modal}>
        <div
          className={`${classes.col} d-flex align-items-center justify-content-center`}
        >
          <img
            src={spaceSelected?.image || "/img-placeholder.jpg"}
            className="img-fluid mh-100 p-4"
            alt="Detail view"
          />
        </div>

        <div className={classes.col}>
          <div className={classes.text}>
            <div className={`ivy-mode ${classes.title}`}>{config?.name}</div>
            <div className={`mb-3 ${classes.subtitle}`}>{config?.subtitle}</div>

            <div
              className={`d-flex ${
                (config?.spaces?.length ?? 0) > 1 ? "mb-4" : ""
              } flex-wrap menu`}
            >
              {config?.spaces &&
                config?.spaces.length > 1 &&
                config?.spaces.map((s, idx) => (
                  <strong
                    key={`space-${idx}`}
                    className={`me-2 mt-2 rounded-pill ${
                      classes.spaceSelector
                    } pointer ${
                      spaceSelected?.id === s.id
                        ? classes.spaceSelectorActive
                        : ""
                    }`}
                    onClick={() => setSpaceSelected(s)}
                  >
                    {s.name}
                  </strong>
                ))}
            </div>

            <div>
              {spaceSelected?.scenes.map((s, idx) => (
                <div
                  key={`scene-${idx}`}
                  className={`mb-2 border ${
                    classes.sceneSelector
                  } pointer d-flex ${
                    (sceneIdFromQuery ?? spaceSelected.id) === s.id
                      ? classes.sceneSelectorActive
                      : ""
                  }`}
                  onClick={() => {
                    setShowModal(false);
                    setIsLoaded(false);
                    appendSceneIdQuery(s.id);
                    viewer?.loadScene(s.id.toString());
                  }}
                >
                  {s.thumbnail && (
                    <img src={s.thumbnail} alt="thumb" width={70} />
                  )}
                  <div className="d-flex align-items-center">
                    <span
                      className={`las la-street-view ms-2 ${classes.icon}`}
                    />
                    <strong className="ms-2">{s.name}</strong>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div
          className={`${classes.closeBtn} pointer`}
          onClick={() => setShowModal(false)}
        >
          <i className="las la-times" />
        </div>
      </div>
    </div>
  );
};

const useStyles = createUseStyles({
  wrap: ({ showModal }: StyleProps) => ({
    zIndex: 999,
    height: "100vh",
    width: "100vw",
    // minHeight: "100vh",
    maxHeight: "-webkit-fill-available",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    background: "rgba(0, 0, 0, 0.6)",
    backdropFilter: "blur(0.5rem)",
    transition: "all ease-in-out 1s",
    opacity: 0,
    visibility: "hidden",
    ...(showModal ? { opacity: 1, visibility: "visible" } : {}),
    [`@media (max-width: 768px)`]: {
      display: "initial"
    }
  }),
  subtitle: { lineHeight: 1.2 },
  modal: {
    alignSelf: "center",
    height: "500px",
    width: "800px",
    background: "white",
    borderRadius: "1rem",
    overflow: "hidden",
    display: "flex",
    position: "relative",
    flexFlow: "row wrap",
    [`@media (max-width: 768px)`]: {
      width: "96%",
      height: "96%",
      flexFlow: "column",
      overflow: "scroll",
      margin: "2%"
    }
  },
  col: {
    alignSelf: "start",
    width: "50%",
    height: "100%",
    overflow: "auto",
    [`@media (max-width: 768px)`]: {
      width: "100%",
      padding: "1rem",
      overflow: "initial"
    }
  },
  text: {
    padding: "3rem 4rem 3rem 0",
    "& > .menu": {
      fontSize: 14
    },
    [`@media (max-width: 768px)`]: {
      padding: 0
    }
  },
  closeBtn: {
    position: "absolute",
    top: 15,
    right: 20,
    fontSize: "1.3rem",
    color: "#B5B5C3",
    transition: "all ease-in-out 0.3s",
    "&:hover": {
      color: "black"
    }
  },
  spaceSelector: {
    padding: "5px 15px",
    border: "1px solid #E1E1E7",
    color: "#B5B5C3",
    transition: "all ease-in-out 0.3s",
    "&:hover": {
      backgroundColor: "#F0F0F3"
    }
  },
  spaceSelectorActive: {
    borderColor: "#F0F0F3",
    color: "black",
    backgroundColor: "#F0F0F3"
  },
  sceneSelector: {
    borderRadius: 8,
    transition: "all ease-in-out 0.3s",
    "&:hover": { backgroundColor: "#F0F0F3" },
    "& > img": {
      borderTopLeftRadius: 8,
      borderBottomLeftRadius: 8
    },
    "& > div": {
      minHeight: 40
    }
  },
  sceneSelectorActive: {
    backgroundColor: "#F0F0F3"
  },
  icon: { fontSize: "1.5rem" },
  title: {
    fontSize: 24
  }
});
