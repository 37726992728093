import { Fragment, useState } from "react";
import { OurConfig, PannellumViewer } from "../../types";
import { Modal } from "./components/Modal";
import { createUseStyles } from "react-jss";
import { useNavigator } from "./hooks";

type NavigatorProps = {
  viewer: PannellumViewer | null;
  config: OurConfig | undefined;
  browser: Bowser.Parser.ParsedResult;
  setIsLoaded: React.Dispatch<React.SetStateAction<boolean>>;
  isOnlyOneScene: boolean;
};

export const Navigator: React.FC<NavigatorProps> = ({
  viewer,
  config,
  browser,
  setIsLoaded,
  isOnlyOneScene
}) => {
  const [showModal, setShowModal] = useState<boolean>(false);

  const classes = useStyles();

  const {
    spaceName,

    toggleFullscreen,
    toggleGyroscope,
    isOrientationActive,
    isOnFullscreen,

    isWebKitMobile,
    isMobile,

    isWebKitTablet,
    isTablet,

    pannellumConfigScene
  } = useNavigator(viewer, config, browser);

  return (
    <Fragment>
      {!isOnlyOneScene && (
        <div
          className={`${classes.navigator} pointer`}
          onClick={() => setShowModal(true)}
        >
          <span className={`las la-angle-up ${classes.arrowBtn}`} />
          <strong>{spaceName}</strong>
          <span className="mx-2 text-secondary">|</span>
          <strong>{pannellumConfigScene?.name}</strong>
        </div>
      )}

      <div
        className={`${classes.controls} controls ${
          isOnlyOneScene ? "one-scene" : ""
        }`}
      >
        {(isMobile || isTablet) && (
          <div
            onClick={toggleGyroscope}
            className={isOrientationActive ? "active" : "animate"}
          >
            <img src="gyroscope-icon.svg" alt="gyroscope-icon" width={20} />
          </div>
        )}

        {(!isWebKitMobile || !isWebKitTablet) && (
          <div
            onClick={toggleFullscreen}
            className={isOnFullscreen ? "active" : ""}
          >
            <i className="las la-expand" />
          </div>
        )}
      </div>

      <Modal
        viewer={viewer}
        showModal={showModal}
        setShowModal={setShowModal}
        config={config}
        setIsLoaded={setIsLoaded}
      />
    </Fragment>
  );
};

const useStyles = createUseStyles({
  "@keyframes pulseControl": {
    "0%": {
      boxShadow: "0 0 0 0 rgba(255,255,255, 0.6)"
    },
    "70%": {
      boxShadow: "0 0 0 25px rgba(255,255,255, 0)"
    },
    "100%": {
      boxShadow: "0 0 0 0 rgba(255,255,255, 0)"
    }
  },
  navigator: {
    zIndex: 999,
    position: "absolute",
    backgroundColor: "white",
    bottom: 0,
    width: 500,
    height: 60,
    left: "calc(50% - 500px / 2)",
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    display: "flex",
    alignItems: "center",
    transition: "height ease-in-out 0.5s, padding-bottom ease-in-out 0.5s",
    paddingTop: 0,
    "&:hover": {
      height: 70,
      paddingBottom: "0.7rem"
    },
    [`@media (max-width: 768px)`]: {
      width: "100%",
      left: 0
    }
  },
  arrowBtn: {
    marginLeft: 25,
    marginRight: 15,
    fontSize: "1.3rem"
  },
  controls: {
    position: "absolute",
    zIndex: 999,
    right: 51,
    display: "flex",
    "& div > i": {
      fontSize: "1.3rem",
      color: "white"
    },
    "& div": {
      cursor: "pointer",
      borderRadius: "50%",
      padding: 10,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      background: "rgba(0,0,0,0.4)",
      width: 40,
      height: 40,
      marginRight: "0.4rem"
    },
    "& div.active": {
      backgroundColor: "white",
      "& > img,i": {
        filter: "invert(1)"
      }
    },
    "& div.animate": {
      animation: "$pulseControl 1s infinite"
    }
  }
});
