import {
  useRef,
  useEffect,
  useState,
  MutableRefObject,
  useMemo,
  Dispatch,
  useCallback
} from "react";
import {
  HotSpotType,
  OurConfig,
  PannellumHotSpot,
  PannellumViewer,
  PannellumWindow
} from "./types";

import {
  appendSceneIdQuery,
  createScenes,
  getSceneIdFromQuery
} from "./utils/utils";
import { useInterval } from "./utils/hooks";
import Bowser from "bowser";
import { MOBILE_PLATFORM_NAME } from "../constants";

const PANNELLUM_HOTSPOT_CLASSNAME = "pnlm-hotspot-base";

export function usePanoramaViewer(
  config?: OurConfig,
  hotSpotDebug?: boolean
): {
  viewer: MutableRefObject<PannellumViewer | null>;
  isLoaded: boolean;
  setIsLoaded: Dispatch<React.SetStateAction<boolean>>;
  browser: Bowser.Parser.ParsedResult;
  handleHotSpot: (
    hotSpot: PannellumHotSpot,
    idx: number,
    hotSpotDivs: NodeListOf<Element>
  ) => void;
} {
  const viewer = useRef<PannellumViewer | null>(null);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const sceneIdFromQuery = getSceneIdFromQuery();

  const browser = Bowser.parse(window.navigator.userAgent);
  const isOnMobile = browser.platform.type === MOBILE_PLATFORM_NAME;

  const panoramaConfig = useMemo(
    () =>
      config
        ? {
            autoLoad: true,
            autoRotate: -2,
            compass: true,
            orientationOnByDefault: isOnMobile,
            hotSpotDebug: hotSpotDebug,
            friction: 0.4,
            hfov: isOnMobile ? 50 : 110,

            default: {
              firstScene: sceneIdFromQuery ?? config.spaces[0].scenes[0].id,
              sceneFadeDuration: 1000
            },

            scenes: createScenes(
              config.spaces.flatMap((s) => s.scenes),
              config.spaces
            )
          }
        : undefined,
    [config, hotSpotDebug, isOnMobile, sceneIdFromQuery]
  );

  useInterval(
    () => {
      // Does the current scene have hotSpots?
      const sceneHaveHotSpots = config?.spaces.some(
        (space) =>
          space.scenes.find(
            (scene) => scene.name === viewer.current?.getConfig().name
          )?.hotSpots?.length! > 0
      );

      // If yes, it looks for html elements inside the canvas container
      // If no, just have the canvas loaded into the DOM
      const check = sceneHaveHotSpots
        ? Array.from(
            viewer.current?.getRenderer()?.getCanvas()?.offsetParent
              ?.children ?? []
          )
            .flatMap((html) => Array.from(html.classList))
            .includes(PANNELLUM_HOTSPOT_CLASSNAME)
        : viewer.current?.getRenderer()?.getCanvas();

      if (check) {
        setIsLoaded(true);
      }
    },
    180,
    !isLoaded
  );

  const handleHotSpot = useCallback(
    (
      hotSpot: PannellumHotSpot,
      idx: number,
      hotSpotDivs: NodeListOf<Element>
    ) => {
      // Add icon
      hotSpotDivs[idx].classList.add("icon", `icon-${hotSpot.icon}`);

      // Handle hotspot type
      switch (hotSpot.type) {
        case HotSpotType.Scene:
          hotSpotDivs[idx].addEventListener("click", () => {
            appendSceneIdQuery(hotSpot.scene.id!);
            setIsLoaded(false);
            viewer.current?.loadScene(
              hotSpot.scene.id!.toString(),
              hotSpot.scene.pitch,
              hotSpot.scene.yaw,
              hotSpot.scene.hfov
            );
          });
          break;

        case HotSpotType.Tooltip:
          hotSpotDivs[idx].classList.add("tooltip-open");
          break;
      }
    },
    []
  );

  useEffect(() => {
    if (panoramaConfig && !viewer.current) {
      const pannellum = (window as unknown as PannellumWindow).pannellum.viewer(
        "panorama",
        panoramaConfig
      );

      viewer.current = pannellum;
    }
  }, [isLoaded, panoramaConfig]);

  return { viewer, isLoaded, setIsLoaded, browser, handleHotSpot };
}
