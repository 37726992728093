import { createUseStyles } from "react-jss";
import { OurConfig } from "../../types";
import { Fragment } from "react";

type AutoplayLockerProps = {
  config: OurConfig | undefined;
  setAutoplay: (val: boolean) => void;
};

export const AutoplayLocker: React.FC<AutoplayLockerProps> = ({
  config,
  setAutoplay
}) => {
  const classes = useStyles();

  return (
    <Fragment>
      {config?.logo && (
        <img
          id="logo"
          className={classes.logo}
          src={config?.logo}
          alt="logo"
          width={100}
        />
      )}

      <div className={classes.locker}>
        <div onClick={() => setAutoplay(true)}>
          <i className="las la-street-view la-2x"></i>
          <div className={classes.text}>WELCOME</div>
        </div>
      </div>
    </Fragment>
  );
};

const useStyles = createUseStyles({
  "@keyframes pulseLocker": {
    "0%": {
      boxShadow: "0 0 0 0 rgba(255,255,255, 0.6)"
    },
    "70%": {
      boxShadow: "0 0 0 30px rgba(255,255,255, 0)"
    },
    "100%": {
      boxShadow: "0 0 0 0 rgba(255,255,255, 0)"
    }
  },
  "@keyframes pulseLockerHover": {
    from: {
      boxShadow: "0 0 0 0 rgba(0,0,0, 0.2)"
    },
    to: {
      boxShadow: "0 0 0 200vw rgba(0,0,0, 0.4)"
    }
  },
  locker: {
    background: "rgba(0,0,0,0.3)",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    position: "absolute",
    zIndex: "1000",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    "& > div": {
      padding: "1rem 2rem",
      backgroundColor: "white",
      borderRadius: "100vh",
      fontWeight: 700,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      aspectRatio: "1/1",
      justifyContent: "center",
      cursor: "pointer",
      animation: "$pulseLocker 1.4s infinite",

      "&:hover": {
        animation: "$pulseLockerHover 2s forwards"
      }
    }
  },

  logo: {
    zIndex: "1001 !important"
  },
  text: {
    fontSize: "0.8rem"
  }
});
