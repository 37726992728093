import { useMemo, useCallback, useState } from "react";
import {
  WEBKIT_ENGINE_NAME,
  MOBILE_PLATFORM_NAME,
  TABLET_PLATFORM_NAME
} from "../../../constants";
import { OurConfig, PannellumViewer } from "../../types";

export function useNavigator(
  viewer: PannellumViewer | null,
  config: OurConfig | undefined,
  browser: Bowser.Parser.ParsedResult
) {
  const [refreshOrientationStateSignal, setRefreshOrientationStateSignal] =
    useState<number>(Math.random());

  const [isOnFullscreen, setIsOnFullscreen] = useState<boolean>(false);

  // To intercept “isOrientationActive” from React you create a state that functions as a signal.
  // With each change you refresh the state in the useState variable
  const isOrientationActive = useMemo<boolean | undefined>(
    () => viewer?.isOrientationActive(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [viewer, refreshOrientationStateSignal]
  );

  // Intercept if is on fullscreen by "fullscreenchange" event
  document.addEventListener("fullscreenchange", () => {
    setIsOnFullscreen(
      !!document.fullscreenElement || !!document.webkitIsFullScreen
    );
  });

  // Attach the refresher signal on the "touchstart" event
  viewer?.on("touchstart", () =>
    setRefreshOrientationStateSignal(Math.random())
  );

  const pannellumConfigScene = useMemo(() => viewer?.getConfig(), [viewer]);

  const spaceName = useMemo(
    () =>
      config?.spaces?.find((space) =>
        space.scenes?.find((scene) => scene.name === pannellumConfigScene?.name)
      )?.name,
    [config?.spaces, pannellumConfigScene?.name]
  );

  const toggleFullscreen = useCallback(() => {
    const isWebKit = browser.engine.name === WEBKIT_ENGINE_NAME;

    if (isWebKit) {
      if (!document.webkitFullscreenElement) {
        document.body.webkitRequestFullscreen!();
      } else {
        document.webkitExitFullscreen!();
      }
      return;
    }

    if (!document.fullscreenElement) {
      document.body.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  }, [browser]);

  const toggleGyroscope = useCallback(() => {
    if (viewer?.isOrientationActive()) {
      viewer?.stopOrientation();
      setRefreshOrientationStateSignal(Math.random());
      return;
    }

    if (!("requestPermission" in DeviceMotionEvent)) {
      viewer?.startOrientation();
      setRefreshOrientationStateSignal(Math.random());
      return;
    }

    return (DeviceMotionEvent as any)
      .requestPermission()
      .then((res: string) => {
        if (res === "granted") {
          viewer?.startOrientation();
          setRefreshOrientationStateSignal(Math.random());
        }
      });
  }, [viewer]);

  const [isWebKitMobile, isWebKitTablet, isMobile, isTablet] = useMemo(
    () => [
      browser.platform.type === MOBILE_PLATFORM_NAME &&
        browser.engine.name === WEBKIT_ENGINE_NAME,

      browser.platform.type === TABLET_PLATFORM_NAME &&
        browser.engine.name === WEBKIT_ENGINE_NAME,

      browser.platform.type === MOBILE_PLATFORM_NAME,

      browser.platform.type === TABLET_PLATFORM_NAME
    ],
    [browser.engine.name, browser.platform.type]
  );

  return {
    spaceName,
    toggleFullscreen,
    toggleGyroscope,
    isOrientationActive,
    isOnFullscreen,

    isWebKitMobile,
    isMobile,

    isWebKitTablet,
    isTablet,

    pannellumConfigScene
  };
}
