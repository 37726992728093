import { useEffect, useRef } from "react";

export function useInterval(
  callback: () => void,
  delay: number | null,
  run?: boolean
) {
  const intervalId = useRef<any>();

  // Set up the interval.
  useEffect(() => {
    // Don't schedule if no delay is specified.
    if (delay === null || !run) {
      if (intervalId.current) {
        clearInterval(intervalId.current);
      }

      return;
    }

    intervalId.current = setInterval(callback, delay);
    return () => clearInterval(intervalId.current);
  }, [callback, delay, run]);
}
