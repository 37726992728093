import { OurConfig } from "./PanoramaViewer/types";

const LOGO_DILS_S3_URL =
  "https://digital-tech.s3.eu-south-1.amazonaws.com/virtual-panorama/logo_dils.png";

const IMAGE_EXTENSIONS_SUPPORTED = [".jpg", ".png", ".jpeg"];

export async function makePreviewConfig(): Promise<OurConfig | undefined> {
  if (!process.env.REACT_APP_PREVIEW_CONFIGURATION_ENDPOINT) {
    return;
  }

  const configurationEndpoint = new URL(
    process.env.REACT_APP_PREVIEW_CONFIGURATION_ENDPOINT
  );

  const s3Endpoint = configurationEndpoint.origin;
  const baseFolder = configurationEndpoint.pathname.replace("/", "");
  const projectPath = window.location.pathname.replace("/", "");

  const s3Res = await fetch(
    `${s3Endpoint}/?list-type=2&delimiter=/&prefix=${baseFolder}/${projectPath}/&showversions=false`
  );

  if (s3Res.status !== 200) {
    return;
  }

  const s3ResText = await s3Res.text();
  const parser = new DOMParser();
  const xml = parser.parseFromString(s3ResText, "text/xml");

  const contents = xml.querySelectorAll("Contents");

  if (contents.length === 0) {
    return;
  }

  const filePath = Array.from(contents).map(
    (c) => c.querySelector("Key")?.innerHTML!
  );

  return {
    logo: LOGO_DILS_S3_URL,
    name: projectPath.toUpperCase(),
    subtitle: "Internal use only",
    spaces: [
      {
        id: 1,
        name: projectPath.toUpperCase(),
        image: "",
        scenes: filePath
          .filter((a) =>
            IMAGE_EXTENSIONS_SUPPORTED.some((ext) => a.includes(ext))
          )
          .sort((a, b) => a.localeCompare(b))
          .map((path, idx) => {
            // Extract the filename from path without the extension
            const fileName = path
              ?.substring(path.lastIndexOf("/") + 1)
              .replace(/\.[^/.]+$/, "")!;

            return {
              id: idx + 1,
              name: fileName,
              hfov: 0,
              yaw: 0,
              pitch: 1,
              thumbnail: "",
              images: { day: `${s3Endpoint}/${path}` },
              hotSpots: []
            };
          })
      }
    ]
  };
}
