export enum HotSpotType {
  Scene = "scene",
  Tooltip = "tooltip"
}

type HotSpot = {
  yaw: number;
  targetYaw: number;
  pitch: number;
  targetPitch: number;
  type: HotSpotType;
  icon: string;
  tooltipLabel: string;
  scene: Partial<Scene>;
};

export type Scene = {
  id: number;
  name: string;
  hfov: number;
  yaw: number;
  pitch: number;
  thumbnail: string;
  images: { day: string };
  hotSpots: HotSpot[];
};

export type PannellumHotSpot = HotSpot & {
  scene: Partial<Scene>;
  text: string;
  div: Object | Element;
};

export type PannellumScenes = Record<
  string,
  Scene & { hotSpots: PannellumHotSpot[]; spaceName: string | undefined }
>;

export type Space = {
  id: number;
  name: string;
  image: string;
  scenes: Scene[];
};

export type OurConfig = {
  name: string;
  subtitle: string;
  logo: string;
  spaces: Space[];
};

export type PannellumConfig = {
  hfov: number;
  minHfov: number;
  multiResMinHfov: boolean;
  maxHfov: number;
  pitch: number;
  yaw: number;
  minYaw: number;
  maxYaw: number;
  roll: number;
  haov: number;
  vaov: number;
  vOffset: number;
  autoRotate: number;
  autoRotateInactivityDelay: number;
  type: string;
  northOffset: number;
  showFullscreenCtrl: boolean;
  dynamic: boolean;
  dynamicUpdate: boolean;
  doubleClickZoom: boolean;
  keyboardZoom: boolean;
  mouseZoom: boolean;
  showZoomCtrl: boolean;
  autoLoad: boolean;
  showControls: boolean;
  orientationOnByDefault: boolean;
  hotSpotDebug: boolean;
  backgroundColor: number[];
  avoidShowingBackground: boolean;
  draggable: boolean;
  disableKeyboardCtrl: boolean;
  crossOrigin: string;
  touchPanSpeedCoeffFactor: number;
  capturedKeyNumbers: number[];
  friction: number;
  strings: PannellumStrings;
  firstScene: number;
  sceneFadeDuration: number;
  id: number;
  name: string;
  thumbnail: string;
  images: {
    day: string;
  };
  hotSpots: PannellumHotSpot[];
  title: string;
  panorama: string;
  scene: number;
  compass: boolean;
  default: {
    firstScene: number;
    sceneFadeDuration: number;
  };
  scenes: PannellumScenes;
};

type PannellumStrings = {
  loadButtonLabel: string;
  loadingLabel: string;
  bylineLabel: string;
  noPanoramaError: string;
  fileAccessError: string;
  malformedURLError: string;
  iOS8WebGLError: string;
  genericWebGLError: string;
  textureSizeError: string;
  unknownError: string;
};

// Ref. https://github.com/mpetroff/pannellum/blob/master/doc/events.md
type PannellumEvent =
  | "load"
  | "scenechange"
  | "fullscreenchange"
  | "zoomchange"
  | "scenechangefadedone"
  | "animatefinished"
  | "error"
  | "errorcleared"
  | "mousedown"
  | "mouseup"
  | "touchstart"
  | "touchend"
  | "messageshown"
  | "messagecleared";

export type PannellumViewer = {
  getConfig: () => PannellumConfig;
  getScene: () => number;
  loadScene: (
    sceneId: string,
    pitch?: number,
    yaw?: number,
    hfov?: number
  ) => void;
  isLoaded: () => boolean | undefined;
  getRenderer: () => {
    isLoading: () => boolean | undefined;
    getCanvas: () => HTMLElement | undefined;
  };
  startOrientation: () => void;
  stopOrientation: () => void;
  isOrientationActive: () => boolean;
  on: (event: PannellumEvent, listener: () => void) => void;
  off: (event: PannellumEvent, listener: () => void) => void;
};

export type PannellumWindow = Window & {
  pannellum: {
    viewer: (id: string, config: Partial<PannellumConfig>) => PannellumViewer;
  };
};
