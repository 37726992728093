import { PannellumScenes, Scene, Space } from "../types";

export function getQueryParams() {
  const urlSearchParams = new URLSearchParams(window.location.search);
  return window.location.search
    ? Object.fromEntries(urlSearchParams.entries())
    : undefined;
}

export function createScenes(scenes: Scene[], spaces: Space[]) {
  return scenes.reduce<PannellumScenes>(
    (acc, scene) => ({
      ...acc,
      [scene.id]: {
        ...scene,
        panorama: scene.images.day,
        hotSpots: scene.hotSpots.map((hotSpot) => ({
          ...hotSpot,
          text:
            hotSpot.tooltipLabel ??
            scenes.find((scene) => scene.id === hotSpot.scene.id)?.name
        }))
      }
    }),
    {}
  );
}

export function appendSceneIdQuery(id: number | string) {
  window.history.replaceState(
    {},
    "",
    `${window.location.pathname}?scene=${id}`
  );
}

export function getSceneIdFromQuery() {
  const queryParams = getQueryParams();
  return queryParams?.scene ? Number(queryParams.scene) : undefined;
}
