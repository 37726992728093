import "pannellum/build/pannellum";
import "pannellum/build/pannellum.css";

import "./index.css";

import { Fragment, useEffect, useMemo, useState } from "react";
import { usePanoramaViewer } from "./hooks";
import { OurConfig } from "./types";
import { Navigator } from "./components/Navigator";
import { getQueryParams } from "./utils/utils";
import { AutoplayLocker } from "./components/AutoplayLocker";

type PanoramaViewerProps = {
  config: OurConfig | undefined;
  navigator?: boolean;
  hotSpotDebug?: boolean;
};

const PanoramaViewer: React.FC<PanoramaViewerProps> = ({
  config,
  navigator = true,
  hotSpotDebug = false
}) => {
  const queryParams = getQueryParams();
  // Convert the autoplay param value 0/1 to a boolean value
  // autoplay=0 => false
  // autoplay=1 => true
  // undefined => true
  const [autoplay, setAutoplay] = useState<boolean>(
    Boolean(Number(queryParams?.autoplay ?? 1))
  );

  const { viewer, isLoaded, setIsLoaded, browser, handleHotSpot } =
    usePanoramaViewer(config, hotSpotDebug);

  useEffect(() => {
    if (!isLoaded || !viewer.current) {
      return;
    }

    // Hack! Because autoLoad duplicate the DOM at first boot
    const [ui, container] = [
      document.querySelectorAll(".pnlm-ui"),
      document.querySelectorAll(".pnlm-render-container")
    ];

    if (ui.length === 2) {
      ui[0].remove();
      container[0].remove();
    }

    // Add dynamic icon to hotSpots
    const hotSpotDivs = document.querySelectorAll(".pnlm-render-container div");

    const pannellumConfig = viewer.current.getConfig();

    pannellumConfig.hotSpots.forEach((hotSpot, idx) => {
      handleHotSpot(hotSpot, idx, hotSpotDivs);
    });
  }, [handleHotSpot, isLoaded, viewer]);

  const isOnlyOneScene = useMemo<boolean>(
    () => config?.spaces.every((space) => space.scenes.length === 1) ?? false,
    [config?.spaces]
  );

  // Configure compass
  useEffect(() => {
    const compassElement = document.getElementsByClassName("pnlm-compass")[0];

    if (isLoaded) {
      if (isOnlyOneScene) {
        compassElement.classList.add("one-scene");
      }
      return;
    }

    if (compassElement) {
      (compassElement as HTMLElement).style.display = "none";
    }
  }, [isLoaded, isOnlyOneScene]);

  if (navigator) {
    return (
      <Fragment>
        {config?.logo && autoplay && (
          <img id="logo" src={config?.logo} alt="logo" width={100} />
        )}

        {!autoplay && (
          <AutoplayLocker config={config} setAutoplay={setAutoplay} />
        )}

        {isLoaded && (
          <Navigator
            viewer={viewer.current}
            config={config}
            setIsLoaded={setIsLoaded}
            browser={browser}
            isOnlyOneScene={isOnlyOneScene}
          />
        )}
      </Fragment>
    );
  }

  return null;
};

export default PanoramaViewer;
